<template>
  <div
    :style="getElementStyle"
    v-if="!profilePage && data.properties.filed_content != 'Hide'"
  >
    <el-row align="middle">
      <el-col
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
          v-if="!data.properties.hideLabel"
          :style="computedStyles"
      >
         <label
           for="text"
          v-if="hasLabel && data.properties.filed_content != 'Hide'"
          :style="getLabelStyles"
          :class="{ 'highlighted-label': highlight }"
        >{{ label||data.label }}</label>
        <span
          v-if="data.validations.required  && hasLabel && !isView"
          style="color: red; font-weight: bold; margin-left: 5px; margin-right: 5px;"
        >*</span>
        <span>
          <el-popover
          v-if="data.description"
          placement="top-start"
          trigger="hover"
          :content="data.description"
        >
          <i
            class="el-icon-info"
            slot="reference"
          ></i>
        </el-popover>
        </span>
      </el-col>
      <el-col v-else :span="showLabel ? 4 : data.description ? 20 : 24" ref="colRef" :style="computedStyles">
            <span v-if="hasLabel" class="transparentcol"></span>
            <span
          v-if="data.validations.required  && hasLabel && !isView"
          style="color: red; font-weight: bold; margin-left: 5px; margin-right: 5px;"
        >*</span>
          </el-col>
      <!-- <el-col :span="data.description ? 4 : 0">
        <el-popover
          v-if="data.description"
          placement="top-start"
          trigger="hover"
          :content="data.description"
        >
          <i
            class="el-icon-info"
            slot="reference"
          ></i>
        </el-popover>
      </el-col> -->
      <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        12
        :
        24">
        <div class="vue-phone-validator">
          <p v-if="!isView && data.properties.filed_content != 'Hide'">
            <VuePhoneNumberInput
              :style="getStyle"
              required
              v-if="data.countryDisable == true"
              v-model="form[data.key]"
              :onlyCountries="[data.defaultCountry]"
              :default-country-code="data.defaultCountry"
              :disabled="checkReadonly() || readonly()"
              :class="{ 'disabled-div': checkReadonly() }"
              @update="onUpdate"
              :readonly="readonly()"
            />

            <VuePhoneNumberInput
              :style="getStyle"
              required
              v-else
              v-model="form[data.key]"
              :disabled="checkReadonly() || readonly()"
              :class="{ 'disabled-div': checkReadonly() }"
              :default-country-code="
                form[`${data.key}_code`]
                  ? form[`${data.key}_code`].countryCode
                  : data.defaultCountry
              "
              @update="onUpdate"
              :readonly="readonly()"
            />
          </p>
        </div>

        <p
          v-if="isView"
          :style="getStyle"
        >
          <template v-if="form[data.key]">+
            <span v-if="
                form[data.key + '_code'] &&
                form[data.key + '_code'].countryCallingCode
              ">{{ form[data.key + "_code"].countryCallingCode }}</span><span v-if="
                form[data.key + '_code'] && form[data.key + '_code'].countryCode
              ">({{ form[data.key + "_code"].countryCode }} )</span>
            {{ form[data.key] }}</template>

          <template v-else>-</template>
        </p>
      </el-col>
    </el-row>
  </div>
  <div v-else>
    <p v-if="isView" :style="getStyle">
      <template v-if="form[data.key]">+
        <span v-if="
          form[data.key + '_code'] &&
          form[data.key + '_code'].countryCallingCode
          ">{{ form[data.key + "_code"].countryCallingCode }}</span><span v-if="
            form[data.key + '_code'] && form[data.key + '_code'].countryCode
          ">({{ form[data.key + "_code"].countryCode }})
        </span>
        {{ form[data.key] }}</template>

      <template v-else>-</template>
    </p>
    <div
      v-else
      class="vue-phone-validator"
    >
      <p v-if="data.properties.filed_content != 'Hide'">
        <VuePhoneNumberInput
          :style="getStyle"
          required
          v-if="data.countryDisable == true"
          v-model="form[data.key]"
          :onlyCountries="[data.defaultCountry]"
          :default-country-code="data.defaultCountry"
          :disabled="checkReadonly() || readonly()"
          :class="{ 'disabled-div': checkReadonly() }"
          @update="onUpdate"
          size="mini"
          :readonly="readonly()"
        />

        <VuePhoneNumberInput
          :style="getStyle"
          required
          v-else
          v-model="form[data.key]"
          :disabled="checkReadonly() || readonly()"
          :class="{ 'disabled-div': checkReadonly() }"
          :default-country-code="
            form[`${data.key}_code`]
              ? form[`${data.key}_code`].countryCode
              : data.defaultCountry
          "
          @update="onUpdate"
          size="mini"
          :readonly="readonly()"
        />
      </p>
    </div>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import { setTimeout } from "timers";

export default {
  name: "templates-formComponentsExecute-PhoneCountryCodeExecute",
  mixins: [userPermissionsHelper],
  components: {
    VuePhoneNumberInput: () => import("vue-phone-number-input")
  },
  props: ["data","label", "country", "form", "isView", "hasLabel", "profilePage","highlight","checkIsDisabled"], 
  computed: {
    getStyle() {
      if(this.profilePage && !this.isView) {
        return `height:${this.data.height - 30}px; font-weight: 400; margin-bottom:60px;`;
      }
      return `height:${this.data.height - 30}px; font-weight: 400`;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    },
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}`
        : "font-weight: 700";
    },
    computedStyles() {
    const styles = {};
    styles.display = 'flex';
    if (this.data?.styles?.labelAlignments) {
      styles.justifyContent = this.data.styles.labelAlignments;
    }
    return styles;
    },
  },
  mounted() {
    //this.defaultMethod();
  },

  data() {
    return {
      isDefalutPosq: true,
      showLabel: true,
      error: true,
      errorMsg: "",

      countryCode: {}
    };
  },

  methods: {
    //    checkReadonly() {
    //   if(this.data.styles && this.data.styles.labelStyle){
    //     if(this.data.styles.labelStyle == 'hide'){
    //       this.showLabel = false;
    //     }
    //     if(this.data.styles.labelStyle == 'right'){
    //       this.isDefalutPos = false;
    //     }
    //   }
    //   return !this.fieldFilledByCurrentUser(this.data.filled_by);
    // },
    readonly() {
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          if (this.data.styles.labelStyle == "right") {
            this.isDefalutPos = false;
          }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },
    defaultMethod() {
      if (
        this.data.default_value &&
        this.form &&
        this.data.key &&
        !this.form[this.data.key]
      ) {
        //  this.form[this.data.key] = this.data.default_value;
        this.$set(this.form, this.data.key, this.data.default_value);
      }
    },
    onUpdate(payload) {
      this.applyFormRules();
      this.form[this.data.key + "_code"] = payload;
      this.data.defaultCountry = payload.countryCode;
      if (!payload.isValid) {
        this.error = true;
        this.errorMsg = `Invalid ${this.data.label}`;
      } else {
        setTimeout(async () => {
          // this.form[this.data.key]=payload;
        }, 1000);

        this.error = false;
        this.errorMsg = "";
      }
    }
  }
};
</script>

<style lang="scss">
.error {
  color: red;
}
.country-selector__input {
  background-color: #eff3fa !important;
  height: 50px !important;
  -webkit-box-shadow: 0px 1px 2px #ddebfa !important;
  box-shadow: 0px 1px 2px #ddebfa !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 1.1em !important;
  letter-spacing: 0.5px !important;
}
.input-tel__input {
  background-color: #eff3fa !important;
  height: 50px !important;
  -webkit-box-shadow: 0px 1px 2px #ddebfa !important;
  box-shadow: 0px 1px 2px #ddebfa !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 1.1em !important;
  letter-spacing: 0.5px !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<style scoped>
.transparentcol{
  color: white; 
  display: inline-block; 
  height: 20px; 
  opacity: 0;
}
</style>
