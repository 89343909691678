<template>
  <div v-if="!refresh">
    <div
      :style="getElementStyle"
      v-if="!loading && !profilePage && data.properties.filed_content != 'Hide'"
    >

    <div v-if="!isDefalutPos" style="display: flex;">
      <div style="white-space: nowrap;">
        <div
          v-if="!data.properties.hideLabel"
        >
          <label
            for="text"
            v-if="hasLabel && data.properties.filed_content != 'Hide'"
            :style="getLabelStyles"
          >{{ label||data.label }}</label>
          <span
            v-if="
              data.validations.required &&
              data.properties.filed_content != 'Hide'
              && hasLabel && !isView
            "
            style="color: red; font-weight: bold; margin-left: 5px; margin-right: 5px;"
          >*</span>
          <span>
            <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
            style="margin-right: 5px;"
          >
            <i
              class="el-icon-info"
              slot="reference"
            ></i>
          </el-popover>
          </span>
        </div>
      </div>
      <el-select
            :style="getStyle"
            :placeholder="data.placeholder"
            v-model="form[data.key]"
            :disabled="isFromDocument ? false : checkReadonly() || readonly()"
            :readonly="readonly()"
            v-if="!isView && !isList && data.properties.filed_content != 'Hide'"
            :suffix-icon="data.properties.el_icon"
            @change="applyFormRules"
            filterable
            remote
            clearable
          >
            <el-option
              v-for="(option, index) of options"
              :value="option"
              :key="index"
            ></el-option>
          </el-select>
          <el-select
            v-if="!isView && isList && data.properties.filed_content != 'Hide'"
            :style="getStyle"
            :placeholder="data.placeholder"
            v-model="form[data.key]"
            :disabled="isFromDocument ? false : checkReadonly() || readonly()"
            :readonly="readonly()"
            @change="applyFormRules"
            :suffix-icon="data.properties.el_icon"
            clearable
          >
            <el-option
              v-for="(option, index) of options"
              :value="option"
              :key="index"
            ></el-option>
          </el-select>
          <!-- <div v-if="!isView && data.default_value">
          <template v-if="data.default_value">{{data.default_value }}</template>
        </div>-->
          <p
            v-if="isView && data.properties.filed_content != 'Hide'"
            :style="getStyle"
          >
            <template v-if="form[data.key]">{{ form[data.key] }}</template>
            <template v-else>-</template>
          </p>
    </div>


      <el-row align="middle" v-else class="topfield">
        <el-col
          :span="showLabel ? 4 : data.description ? 20 : 24"
          v-if="!data.properties.hideLabel"
           ref="colRef" :style="computedStyles"
        >
          <label
            for="text"
            v-if="hasLabel && data.properties.filed_content != 'Hide'"
            :style="getLabelStyles" class="label"
          >{{ label||data.label}}</label>
          <span
            v-if="
              data.validations.required &&
              data.properties.filed_content != 'Hide'
              && hasLabel && !isView
            "
            style="color: red; font-weight: bold; margin-left: 5px; margin-right: 5px;"
          >*</span>
          <span>
            <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
            >
            <i
              class="el-icon-info"
              slot="reference"
            ></i>
            </el-popover>
          </span>
        </el-col>
        <el-col v-else :span="showLabel ? 4 : data.description ? 20 : 24" ref="colRef" :style="computedStyles">
        <span v-if="hasLabel" style="color: white; display: inline-block; height: 20px; opacity: 0;" ></span>
        <span
            v-if="
              data.validations.required &&
              data.properties.filed_content != 'Hide'
              && hasLabel && !isView
            "
            style="color: red; font-weight: bold; margin-left: 5px; margin-right: 5px;"
          >*</span>
      </el-col>
        <!-- <el-col :span="data.description ? 2 : 0">
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i
              class="el-icon-info"
              slot="reference"
            ></i>
          </el-popover>
        </el-col> -->
        <el-col :span="showLabel ? 12 : 24" class="extendable">
          <el-select
            :style="getStyle"
            :placeholder="data.placeholder"
            v-model="form[data.key]"
            :disabled="isFromDocument ? false : checkReadonly() || readonly()"
            :readonly="readonly()"
            v-if="!isView && !isList && data.properties.filed_content != 'Hide'"
            :suffix-icon="data.properties.el_icon"
            @change="applyFormRules"
            filterable
            remote
            clearable
            :class="{'highlighted-border': highlight}"
          >
            <el-option
              v-for="(option, index) of options"
              :value="option"
              :key="index"
            ></el-option>
          </el-select>
          <el-select
            v-if="!isView && isList && data.properties.filed_content != 'Hide'"
            :style="getStyle"
            :placeholder="data.placeholder"
            v-model="form[data.key]"
            :disabled="isFromDocument ? false : checkReadonly() || readonly()"
            :readonly="readonly()"
            @change="applyFormRules"
            :suffix-icon="data.properties.el_icon"
            clearable
          >
            <el-option
              v-for="(option, index) of options"
              :value="option"
              :key="index"
            ></el-option>
          </el-select>
          <!-- <div v-if="!isView && data.default_value">
          <template v-if="data.default_value">{{data.default_value }}</template>
        </div>-->
          <p
            v-if="isView && data.properties.filed_content != 'Hide'"
            :style="getStyle"
          >
            <template v-if="form[data.key]">{{ form[data.key] }}</template>
            <template v-else>-</template>
          </p>
        </el-col>
      </el-row>
    </div>
    <div v-else>
      <div v-if="isView && data.properties.filed_content != 'Hide'">
        {{ form[data.key] }}
      </div>
      <div v-else>
        <el-select
          v-if="data.properties.filed_content != 'Hide'"
          :style="getStyle"
          :placeholder="data.placeholder"
          v-model="form[data.key]"
          :disabled="isFromDocument ? false : checkReadonly()"
          :readonly="readonly()"
          @change="applyFormRules"
          :suffix-icon="data.properties.el_icon"
          clearable
        >
          <el-option
            v-for="(option, index) of options"
            :value="option"
            :key="index"
          ></el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import { mapGetters } from "vuex";
export default {
  name: "templates-formComponentsExecute-SelectExecute",
  mixins: [userPermissionsHelper],
  components: {},
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "checkIsDisabled",
    "checkhideFields",
    "profilePage",
    "colorFields",
    "highlight"
    
  ],
  data() {
    return {
      validations: [],
      options: [],
      isList: false,
      showLabel: true,
      isDefalutPos: true,
      loading: false,
      refresh: true
    };
  },
  mounted() {
    this.loading = true;
    this.options = this.data.options;
    //this.defaultMethod();
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    if(this.form[this.data.key] !== ''){
      this.applyFormRules()
    }
    //check data format
    this.checkDataFormat();
    this.loading = false;
    setTimeout(() => {
      this.refresh = false;
    }, 100);
  },
  computed: {
    getStyle() {
      return `height:${this.data.height - 30}px; width: 100%`;
    },
    // isDefalutPos() {
    //   if (this.data.styles && this.data.styles.labelStyle == "right") {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // },
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = 'flex';

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
  },
  methods: {
defaultMethod() {
      if (
        this.data.default_value &&
        this.form &&
        this.data.key &&
        !this.form[this.data.key]
      ) {
        //  this.form[this.data.key] = this.data.default_value;
        this.$set(this.form, this.data.key, this.data.default_value);
      } else if (
        !this.form[this.data.key] &&
        this.data.default_mapped_field &&
        this.form[this.data.default_mapped_field]
      ) {
        this.$set(
          this.form,
          this.data.key,
          this.form[this.data.default_mapped_field]
        );
      }
      this.$emit("applyFormRules");
    },
    checkDataFormat() {
      if (
        this.form[this.data.key] &&
        typeof this.form[this.data.key] == "object" &&
        this.form[this.data.key].length
      ) {
        this.$set(this.form, this.data.key, this.form[this.data.key][0]);
      }
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    readonly() {
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    // checkReadonly() {
    //   if(this.checkIsDisabled){
    //     return true
    //   }
    //   if(this.data.styles && this.data.styles.labelStyle){
    //     if(this.data.styles.labelStyle == 'hide'){
    //       this.showLabel = false;
    //     }
    //     if(this.data.styles.labelStyle == 'right'){
    //       this.isDefalutPos = false;
    //     }
    //   }
    //   //If it's an entity varaible, have to check access
    //   if(this.data.field_assignable === 'read_only' && this.data.is_entity_variable){
    //     return true
    //   }
    //   return !this.fieldFilledByCurrentUser(this.data.filled_by);
    // },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles && this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          if (this.data.styles && this.data.styles.labelStyle == "right") {
            this.isDefalutPos = false;
          }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },

    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type == "SELECT") {
          this.isList = true;
          this.options = this.getGlobalVariableById.options;
        } else {
          this.options = this.getGlobalVariableById.options;
        }
      }
    }
  },
  watch: {
    // $props: {
    //   handler() {
    //     this.defaultMethod();
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  // methods: {
  //   // checkReadonly() {
  //   //   if(this.data.styles && this.data.styles.labelStyle){
  //   //     if(this.data.styles.labelStyle == 'hide'){
  //   //       this.showLabel = false;
  //   //     }
  //   //     if(this.data.styles.labelStyle == 'right'){
  //   //       this.isDefalutPos = false;
  //   //     }
  //   //   }
  //   //   return !this.fieldFilledByCurrentUser(this.data.filled_by);
  //   // },
  //   readonly() {
  //     if (this.data && this.data.properties && this.data.properties.filed_content === "READ ONLY") {
  //       return true;
  //     }
  //   },
  //   checkReadonly() {
  //     if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
  //       if (this.data.styles && this.data.styles.labelStyle) {
  //         if (this.data.styles.labelStyle == "hide") {
  //           this.showLabel = false;
  //         }
  //         if (this.data.styles.labelStyle == "right") {
  //           this.isDefalutPos = false;
  //         }
  //       }
  //       return !this.fieldFilledByCurrentUser(this.data.filled_by);
  //     } else {
  //       if (this.data && this.data.properties && this.data.properties.filed_content === "DISABLED") {
  //         return true;
  //       }
  //       return !this.fieldFilledByCurrentUser(this.data.filled_by);
  //     }

  //   },
  //   // getElementStyle() {
  //   //   let borderStyle = "";
  //   //   if (this.data.styles) {
  //   //     let type = this.data.styles.border_type
  //   //       ? this.data.styles.border_type
  //   //       : "solid";
  //   //     let size = this.data.styles.border_size
  //   //       ? this.data.styles.border_size + "px"
  //   //       : "0px";
  //   //     let color = this.data.styles.border_color
  //   //       ? this.data.styles.border_color
  //   //       : "";

  //   //     borderStyle = "border:" + type + " " + " " + size + " " + color;

  //   //     borderStyle += ";";
  //   //     borderStyle += this.data.styles.background
  //   //       ? `background: ${this.data.styles.background}`
  //   //       : "";
  //   //   }
  //   //   return borderStyle;
  //   // },
  // }
};
</script>

<style lang="scss" scoped>
.label{
  white-space: nowrap;
}
.topfield{
  display: flex;
  flex-direction: column;
}
.extendable{
  display: contents;
}
</style>
