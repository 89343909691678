<template>
  <div :style="getElementStyle" v-if="data.properties.filed_content != 'Hide'">
    <div>
      <component :is="selectedComponent" v-loading="loading" :data="selectedField" :form="form" :is-view="isView"
        :hasLabel="hasLabel" :fieldsData="fieldsData" :isFromDocument="isFromDocument" :isEntityVariable="true"
        :originalField="data" @entityDataUpdated="entityDataUpdated" @clearEntityFields="clearEntityFields"
        :checkIsDisabled=true @applyFormRules="rulesEventEmitter" :parentFormData="parentFormData"
        :dataTableRowIndex="dataTableRowIndex"></component>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import DateExecute from "../formComponentsExecute/DateExecute";
import SingleLineTextExecute from "../formComponentsExecute/SingleLineTextExecute";
import MultiLineTextExecute from "../formComponentsExecute/MultiLineTextExecute";
import NumberExecute from "../formComponentsExecute/NumberExecute";
import SelectExecute from "../formComponentsExecute/SelectExecute";

import EntityVariableExecute from "../formComponentsExecute/EntityVariableExecute";


import CurrencyExecute from "../formComponentsExecute/CurrencyExecute.vue";
import PhoneCountryCodeExecute from "../formComponentsExecute/PhoneCountryCodeExecute";
import DateTimeExecute from "../formComponentsExecute/DateTimeExecute";
import DateTimeRangeExecute from "../formComponentsExecute/DateTimeRangeExecute";
import DateRangeExecute from "../formComponentsExecute/DateRangeExecute";
import TimeExecute from "../formComponentsExecute/TimeExecute";
export default {
  name: "templates-formComponentsExecute-PayVariableExecute",
  components: {
    //Execute

    DateExecute,
    MultiLineTextExecute,
    SingleLineTextExecute,
    NumberExecute,
    SelectExecute,
    EntityVariableExecute,
    CurrencyExecute,
    PhoneCountryCodeExecute,
    DateTimeExecute,
    DateTimeRangeExecute,
    DateRangeExecute,
    TimeExecute,
  },
  props: [
    "data",
    "value",
    "form",
    "isView",
    "hasLabel",
    "fieldsData",
    "isFromDocument",
    "checkIsDisabled",
    "parentFormData",
    "dataTableRowIndex"
  ],
  computed: {
    ...mapGetters("users", ["getUsersList"]),
    ...mapGetters("companyEntities", ["getSingleCompanyEntity"]),
    ...mapGetters("entitiesData", [
      "getAllEntityRelationshipDataByIds",
      "getAllEntitiesDataByIds"
    ]),
    ...mapGetters("companyTemplates", ["getSingleCompanyTemplate"]),
    ...mapGetters("standardFields", [
      "getFList",
      "getField",
      "getGeneralErrorMessage"
    ]),

    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    getStyle() {
      return `height:${this.data.height - 30}px`;
    },
    // Tharuni Start
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size = this.data.styles.font.font_size
          ? this.data.styles.font.font_size
          : "";
        let bold =
          this.data.styles.font.style == 1 || this.data.styles.font.style == 3
            ? "bold"
            : "";
        let italic =
          this.data.styles.font.style == 2 || this.data.styles.font.style == 3
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
      }
      return borderStyle;
    },
    // Tharuni End
    getAllUsers() {
      return this.getUsersList && this.getUsersList.data
        ? this.getUsersList.data
        : [];
    },

    getAllEntities() {
      return this.getAllEntitiesData && this.getAllEntitiesData.data
        ? this.getAllEntitiesData.data
        : [];
    },
    getPrimaryDataForUser() {
      return user => {
        let primaryFields = user.user_type.allowed_fields.filter(
          e => e.primary
        );

        let data = [];

        primaryFields.forEach(field => {
          data.push(user[field.key]);
        });

        return data.join(" ");
      };
    },
    getPrimaryDataForEntity() {
      return entity => {
        let primaryFields = entity.entity_id.primary_fields || [];

        let data = [];
        primaryFields.forEach(field => {
          if (entity.entity_data) {
            data.push(entity.entity_data[field.key]);
          }
        });

        return data.join(" ");
      };
    }
  },
  data() {
    return {
      validations: [],
      options: [],
      selectedEntity: {},
      selectedField: {},
      selectedEntityFields: [],
      selectedComponent: "",
      refField: {},
      loading: false,
      actionFieldData: [],
      entityData: []
    };
  },

  updated: function () {
    this.$nextTick(function () {
      this.$emit("UIUpdated", {});
    });
  },
  async mounted() {
    this.options = this.data.options || [];
    await this.setFiledType();
  },
  methods: {
    async setFiledType() {
      
      if (this.data.global_variable_pay_field && (this.data.global_variable_pay_field == "amount" || this.data.global_variable_pay_field == "amountRefunded" || this.data.global_variable_pay_field == "paidAmount" || this.data.global_variable_pay_field == "totalPaidAmount")) {
        this.data.inputType = "CURRENCY";
      }
      this.selectedField = { ...this.data };
      this.$set(this.selectedField, "field_assignable", "read_only");
      this.setEntityFields();
      if (this.data.key && this.data.parent_key && this.form[this.data.parent_key + '_info'] && this.data.global_variable_pay_field && this.form[this.data.parent_key + '_info'][this.data.global_variable_pay_field]) {
        if (this.data.global_variable_pay_field && (this.data.global_variable_pay_field == "amount" || this.data.global_variable_pay_field == "amountRefunded" || this.data.global_variable_pay_field == "paidAmount" || this.data.global_variable_pay_field == "totalPaidAmount")) {
          this.data.inputType = "CURRENCY";
          this.data.validations.currency = this.form && this.data.parent_key && this.form[this.data.parent_key + '_info'] && this.form[this.data.parent_key + '_info']['currency'] ? this.form[this.data.parent_key + '_info']['currency'] : "USD";
        }
      }
      else {
       this.data.validations.currency = "USD";
        // form[data.key]
       if(this.form && this.data.parent_key &&this.data.global_variable_pay_field && this.form[this.data.parent_key + '_info'] && this.form[this.data.parent_key + '_info'][this.data.global_variable_pay_field] )
        {
          this.$set(this.form, this.data.parent_key + '_info'[this.data.global_variable_pay_field], 0);
        }
      }
      this.selectedField.is_entity_variable = true;
    },
    rulesEventEmitter() {
      this.$emit("applyFormRules");
    },
    async fetchOptions() {
      try {
        await this.checkWithParentKey(this.fieldsData);

        if (this.data.data_table_field_index > -1) {
          // we need to inlcude table entity fields here
          this.fieldsData
            .filter(field => field.input_type == "DATA_TABLE")
            .forEach(
              async field =>
                await this.checkWithParentKey(field.data_table_columns)
            );
        }
      } catch (error) {
        console.log("fetchOptions",error);
      }
    },

    async checkWithParentKey(fieldsData) {
      const parentField = fieldsData.find(
        e => e.key == this.data.relationship_key
      );
      if (parentField && parentField.entity_id) {
        if (this.data.global_variable_entity_field.includes("~")) {
          let relationshipId = this.data.global_variable_entity_field.split(
            "~"
          )[0];
          let filters = this.data?.filters ? this.data.filters : [];
          this.selectedField = {
            action_button_repeating_template_relationships: [],
            input_type: "ENTITY",
            inputType: "ENTITY",
            content: "",
            selected_fields: [],
            formula: "",
            function_type: "",
            validations: {
              required: false,
              type: "OBJECT_ID",
              data_type: "OBJECT_ID"
            },
            defaultCountry: "US",
            countryCodeSelector: true,
            countryDisable: false,
            styles: {
              font: {
                name: "Helvetica",
                size: 16,
                style: 0,
                color: "#000000"
              }
            },
            properties: {
              hideLabel: false,
              prefix: false,
              is_unique: false,
              personal_data: false,
              personal_data_type: "",
              is_encrypted: false
            },
            decimals: null,
            date_result_type: "",

            filled_by: this.data.filled_by,
            type: "FIELD",

            is_global_variable: false,

            filters: [
              ...filters,
              ...[
                {
                  field: "self#related_to/1",
                  operator: "=",
                  value: "",
                  query_type: "AND",
                  data_type: "RELATION",
                  data_source: "self_field",
                  value_field: this.data.global_variable_entity_parent,
                  relation_entity_id: parentField.entity_id
                }
              ]
            ],
            entity_id: relationshipId,
            visibility: true,
            height: this.data.height,
            width: this.data.width
          };

          this.selectedField.is_entity_field = false;
          this.setEntityFields();
        } else if (this.data.global_variable_entity_field.includes("#")) {
          let templateId = this.data.global_variable_entity_field.includes("#")
            ? this.data.global_variable_entity_field.split("#")[0]
            : this.data.global_variable_entity_field;
          await this.$store.dispatch(
            "companyTemplates/fetchSingleCompanyTemplate",
            templateId
          );
          if (this.getSingleCompanyTemplate) {
            this.entitiesData = this.getSingleCompanyTemplate;
            this.setEntityFields();

            let selectedEntity = this.getSingleCompanyTemplate;
            this.selectedEntity = selectedEntity;
            this.selectedEntityFields = [];
            this.selectedEntityFields = this.getTemplateFields(
              this.getSingleCompanyTemplate
            );
            let templateId = this.data.global_variable_entity_field.includes(
              "#"
            )
              ? this.data.global_variable_entity_field.split("#")[1]
              : this.data.global_variable_entity_field;
            this.selectedField = this.selectedEntityFields.find(
              f => f.key == templateId
            );
            this.selectedField.filters = this.data.filters || [];
            this.selectedField.filled_by = this.data.filled_by;
            this.selectedField.is_entity_field = true;
            this.setEntityFields();
          }
        }

        this.selectedField.height = this.data.height;
        this.selectedField.width = this.data.width;

        this.selectedField.is_entity_variable = true;
        if (this.data.field_assignable) {
          this.$set(
            this.selectedField,
            "field_assignable",
            this.data.field_assignable
          );
        } else {
          this.$set(this.selectedField, "field_assignable", "read_only");
        }
      }
    },

    entityDataUpdated(param1, param2, param3, param4, index) {
      this.$emit("entityDataUpdated", param1, param2, param3, param4, index);
    },
    clearEntityFields(data) {
      this.$emit("clearEntityFields", data);
    },

    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach(e => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map(e => {
        e.template_id = data._id;
        return e;
      });
      return fields;
    },
    setEntityFields() {
      if (this.selectedField) {
        let fieldMap = {
          DATE: "DateExecute",
          FILE: "FileExecute",
          MULTI_LINE_TEXT: "MultiLineTextExecute",
          SINGLE_LINE_TEXT: "SingleLineTextExecute",
          MULTI_SELECT: "MultiSelectExecute",
          NUMBER: "NumberExecute",
          SELECT: "SelectExecute",
          LIST: "ListExecute",
          YES_OR_NO: "YesOrNoExecute",
          HEADING: "HeadingExecute",
          CHECKBOX: "CheckBoxExecute",
          ESIGNATURE: "ESignatureExecute",
          GLOBAL_VAIRLABE: "GlobalVariableExecute",
          ENTITY_VARIABLE: "EntityVariableExecute",
          PARAGRAPH: "ParagraphExecute",
          SINGLE_LINE_CONTENT: "SingleLineContentExecute",
          AUTHORIZED_SIGNATURE: "AuthorizedSignatureExecute",
          IMAGE: "ImageExecute",
          FORMULA: "FormulaExecute",
          ACTION_BUTTON: "ActionButtonExecute",
          HTML: "HtmlExecute",
          ENTITY: "EntityExecute",
          CURRENCY: "CurrencyExecute",
          PHONE_COUNTRY_CODE: "PhoneCountryCodeExecute",
          DATE_TIME: "DateTimeExecute",
          DATE_TIME_RANGE: "DateTimeRangeExecute",
          DATE_RANGE: "DateRangeExecute",
          TIME: "TimeExecute",
          RADIO: "RadioExecute",
          WEEKDAYS: "WeekDaysExecute",
          TIME_RANGE: "TimeRangeExecute",
          CHECKBOX_GROUP: "CheckBoxGroupExecute",
          AGGREGATE_FUNCTION: "AggregateFunctionExecute",
          STAR_RATING: "StarRatingExecute",

        };
        this.selectedComponent = fieldMap[this.selectedField.inputType];
      }
    },

    async getCompoentForField(data) {
      try {
        let params = "form=true&content=true";
        await this.$store.dispatch("standardFields/getFieldLists", params);
        if (this.getFList) {
          let dFieldList = [
            ...this.getFList.allFormFields,
            ...this.getFList.allContentFields
          ];
          let field = dFieldList.find(x => x.inputType == data.input_type);
          if (field) {
            return field.components.execute;
          }
          return "";
        }
      } catch (error) {
        console.log("getCompoentForField",error);
      }
    },
    async fetchEntitiesData(entityDataId, relationships, relationshipEntityId) {
      let relationship = relationships.find(
        x => x.entity_id == relationshipEntityId
      );
      if (relationship) {
        await this.$store.dispatch(
          "entitiesData/fetchEntityRelationshipsDataByIds",
          {
            relationship_entity_id: relationshipEntityId,
            relationship_id: relationship._id
          }
        );

        if (this.getAllEntityRelationshipDataByIds) {
          let relationshipData = this.getAllEntityRelationshipDataByIds.find(
            x => x.entity_data_id == entityDataId
          );

          if (relationshipData) {
            await this.$store.dispatch("entitiesData/getEntitiesDataByIds", {
              entity_data_ids: relationshipData.relationship_entity_data_ids
            });
            if (this.getAllEntitiesDataByIds) {
              this.data.options = this.getAllEntitiesDataByIds.data;
            }
          }
        }
      }
    },
    async getFieldData(templateId) {
      try {
        await this.$store.dispatch(
          "companyTemplates/fetchSingleCompanyTemplate",
          templateId
        );
        if (this.getSingleCompanyTemplate) {
          this.actionFieldData.push(this.getSingleCompanyTemplate);
          return this.getSingleCompanyTemplate;
        }
      } catch (error) {
        console.log("getFileData",error);
      }
    }
  },
  watch: {
    "data.global_variable_entity_field": {
      async handler() {
        // do stuff
        await this.fetchOptions();
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100% !important;

  .el-input,
  .el-input__inner {
    height: inherit !important;
  }
}
</style>
